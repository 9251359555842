.row-dragging {
	background: #fafafa;
	border: 1px solid #ccc;
	z-index: 10;
}

.row-dragging td {
	padding: 16px;
}

.row-dragging .drag-visible {
	visibility: visible;
}
.paymentModal .ant-modal-content .ant-modal-body {
	margin-bottom: 60px;
}
